import { useState, useEffect, SyntheticEvent } from 'react';
import { useParams } from 'react-router-dom';
import { FormControl, Button, Typography } from '@mui/material';
import { load } from 'js-yaml';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
// import { AutentiaJs } from '@autentia/plugin-autentia'
import { v4 as uuidv4 } from 'uuid';
import { Autentia } from '../lib/autentia';

import logo from '../logo.png';

export const HomeComponent = () => {
  const [host, setHost] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [config, setConfig] = useState<any>({
    trx: '../Test/GrabaIni',
    section: 'autentia',
    item: 'Prueba',
    file: 'C:/autentia/bin/autentia.ini',
  });

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/host-config.yaml')
      .then((res) => res.text())
      .then((yaml) => load(yaml))
      .then((data: any) => {
        if (data) {
          setConfig(data.config);
          if (id) {
            const hostFound = data.hosts.find((host: any) => host.id === id);
            if (hostFound) {
              setHost(hostFound.host);
            } else {
              setError('Config para "' + id + '" no existe');
            }
          } else {
            const hostFound = data.hosts.find(
              (host: any) => host.id === 'default-host'
            );
            console.log(hostFound);
            setHost(hostFound.host);
          }
        } else {
          setError('Error al leer archivo de configuración');
        }
      });
  }, [id]);

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    // Send host to trx
    try {
      console.log(config);
      Autentia.Transaccion({
        trxName: config.trx,
        hookAutentia: true,
        token: uuidv4(),
        input: {
          // @ts-ignore
          ArchivoIni: config.file,
          Seccion: config.section,
          Item: config.item,
          Valor: host,
        },
        output: ['Erc', 'ErcDesc'],
        excResp: (res) => {
          console.log(res);
          if (res.MBerc && res.MBerc !== 0) {
            setError(
              `Error al llamar a Autentia: 
              ${res.MBercText} (${res.MBerc})`
            );
          } else {
            if (res.ParamsGet.erc !== 0) {
              setError(`Error transacción:
              ${res.ParamsGet.ercText} (${res.ParamsGet.erc})`);
            } else {
              setSuccess(true);
            }
          }
        },
      });
    } catch (e) {
      console.log(e);
      // error AutentiaJS
      setError('Error al actualizar host');
    }
  };

  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />{' '}
      {(success && (
        <p className="success">
          <DoneIcon sx={{ height: '2em', width: '2em' }} color="success" />
          <br />
          Host actualizado
        </p>
      )) ||
        (error && (
          <p className="error">
            <CloseIcon sx={{ height: '2em', width: '2em' }} color="error" />
            <br />
            {error}
          </p>
        )) || (
          <form onSubmit={onSubmit}>
            <FormControl>
              <Typography>Actualizar host a:</Typography>
            </FormControl>
            <br />
            <br />
            <FormControl>
              <Button type="submit" variant="contained" color="primary">
                {host}
              </Button>
            </FormControl>
          </form>
        )}
    </header>
  );
};
