import { BigInteger } from './jsbn';

const b64map =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
const b64pad = '=';
const BI_RM = '0123456789abcdefghijklmnopqrstuvwxyz';

function int2char(n) {
  return BI_RM.charAt(n);
}
/*! asn1hex-1.1.6.js (c) 2012-2015 Kenji Urushima | kjur.github.com/jsrsasign/license
 */
/* eslint-disable */
export const ASN1HEX = new (function () {
  this.getByteLengthOfL_AtObj = function (b, c) {
    if (b.substring(c + 2, c + 3) != '8') {
      return 1;
    }
    const a = parseInt(b.substring(c + 3, c + 4));
    if (a == 0) {
      return -1;
    }
    if (a > 0 && a < 10) {
      return a + 1;
    }
    return -2;
  };
  this.getHexOfL_AtObj = function (b, c) {
    const a = this.getByteLengthOfL_AtObj(b, c);
    if (a < 1) {
      return '';
    }
    return b.substring(c + 2, c + 2 + a * 2);
  };
  this.getIntOfL_AtObj = function (c, d) {
    const b = this.getHexOfL_AtObj(c, d);
    if (b == '') {
      return -1;
    }
    let a;
    if (parseInt(b.substring(0, 1)) < 8) {
      a = new BigInteger(b, 16);
    } else {
      a = new BigInteger(b.substring(2), 16);
    }
    return a.intValue();
  };
  this.getStartPosOfV_AtObj = function (b, c) {
    const a = this.getByteLengthOfL_AtObj(b, c);
    if (a < 0) {
      return a;
    }
    return c + (a + 1) * 2;
  };
  this.getHexOfV_AtObj = function (c, d) {
    const b = this.getStartPosOfV_AtObj(c, d);
    const a = this.getIntOfL_AtObj(c, d);
    return c.substring(b, b + a * 2);
  };
  this.getHexOfTLV_AtObj = function (c, e) {
    const b = c.substr(e, 2);
    const d = this.getHexOfL_AtObj(c, e);
    const a = this.getHexOfV_AtObj(c, e);
    return b + d + a;
  };
  this.getPosOfNextSibling_AtObj = function (c, d) {
    const b = this.getStartPosOfV_AtObj(c, d);
    const a = this.getIntOfL_AtObj(c, d);
    return b + a * 2;
  };
  this.getPosArrayOfChildren_AtObj = function (f, j) {
    const c = new Array();
    const i = this.getStartPosOfV_AtObj(f, j);
    c.push(i);
    const b = this.getIntOfL_AtObj(f, j);
    let g = i;
    let d = 0;
    while (1) {
      const e = this.getPosOfNextSibling_AtObj(f, g);
      if (e == null || e - i >= b * 2) {
        break;
      }
      if (d >= 200) {
        break;
      }
      c.push(e);
      g = e;
      d++;
    }
    return c;
  };
  this.getNthChildIndex_AtObj = function (d, b, e) {
    const c = this.getPosArrayOfChildren_AtObj(d, b);
    return c[e];
  };
  this.getDecendantIndexByNthList = function (e, d, c) {
    if (c.length == 0) {
      return d;
    }
    const f = c.shift();
    const b = this.getPosArrayOfChildren_AtObj(e, d);
    return this.getDecendantIndexByNthList(e, b[f], c);
  };
  this.getDecendantHexTLVByNthList = function (d, c, b) {
    const a = this.getDecendantIndexByNthList(d, c, b);
    return this.getHexOfTLV_AtObj(d, a);
  };
  this.getDecendantHexVByNthList = function (d, c, b) {
    const a = this.getDecendantIndexByNthList(d, c, b);
    return this.getHexOfV_AtObj(d, a);
  };
})();
ASN1HEX.getVbyList = function (d, c, b, e) {
  const a = this.getDecendantIndexByNthList(d, c, b);
  if (a === undefined) {
    throw "can't find nthList object";
  }
  if (e !== undefined) {
    if (d.substr(a, 2) != e) {
      throw `checking tag doesn't match: ${d.substr(a, 2)}!=${e}`;
    }
  }
  return this.getHexOfV_AtObj(d, a);
};
ASN1HEX.hextooidstr = function (e) {
  const h = function (b, a) {
    if (b.length >= a) {
      return b;
    }
    return new Array(a - b.length + 1).join('0') + b;
  };
  const l = [];
  const o = e.substr(0, 2);
  const f = parseInt(o, 16);
  l[0] = new String(Math.floor(f / 40));
  l[1] = new String(f % 40);
  const m = e.substr(2);
  const k = [];
  for (var g = 0; g < m.length / 2; g++) {
    k.push(parseInt(m.substr(g * 2, 2), 16));
  }
  const j = [];
  let d = '';
  for (var g = 0; g < k.length; g++) {
    if (k[g] & 128) {
      d += h((k[g] & 127).toString(2), 7);
    } else {
      d += h((k[g] & 127).toString(2), 7);
      j.push(new String(parseInt(d, 2)));
      d = '';
    }
  }
  let n = l.join('.');
  if (j.length > 0) {
    n = `${n}.${j.join('.')}`;
  }
  return n;
};
ASN1HEX.dump = function (e, c, k, g) {
  const o = function (w, i) {
    if (w.length <= i * 2) {
      return w;
    }
    const v = `${w.substr(0, i)}..(total ${w.length / 2}bytes)..${w.substr(
      w.length - i,
      i
    )}`;
    return v;
  };
  if (c === undefined) {
    c = { ommit_long_octet: 32 };
  }
  if (k === undefined) {
    k = 0;
  }
  if (g === undefined) {
    g = '';
  }
  const r = c.ommit_long_octet;
  if (e.substr(k, 2) == '01') {
    var h = ASN1HEX.getHexOfV_AtObj(e, k);
    if (h == '00') {
      return `${g}BOOLEAN FALSE\n`;
    }
    return `${g}BOOLEAN TRUE\n`;
  }
  if (e.substr(k, 2) == '02') {
    var h = ASN1HEX.getHexOfV_AtObj(e, k);
    return `${g}INTEGER ${o(h, r)}\n`;
  }
  if (e.substr(k, 2) == '03') {
    var h = ASN1HEX.getHexOfV_AtObj(e, k);
    return `${g}BITSTRING ${o(h, r)}\n`;
  }
  if (e.substr(k, 2) == '04') {
    var h = ASN1HEX.getHexOfV_AtObj(e, k);
    if (ASN1HEX.isASN1HEX(h)) {
      var j = `${g}OCTETSTRING, encapsulates\n`;
      j += ASN1HEX.dump(h, c, 0, `${g}  `);
      return j;
    }
    return `${g}OCTETSTRING ${o(h, r)}\n`;
  }
  if (e.substr(k, 2) == '05') {
    return `${g}NULL\n`;
  }
  if (e.substr(k, 2) == '06') {
    const l = ASN1HEX.getHexOfV_AtObj(e, k);
    var a = KJUR.asn1.ASN1Util.oidHexToInt(l);
    var n = KJUR.asn1.x509.OID.oid2name(a);
    const b = a.replace(/\./g, ' ');
    if (n != '') {
      return `${g}ObjectIdentifier ${n} (${b})\n`;
    }
    return `${g}ObjectIdentifier (${b})\n`;
  }
  if (e.substr(k, 2) == '0c') {
    return `${g}UTF8String '${hextoutf8(ASN1HEX.getHexOfV_AtObj(e, k))}'\n`;
  }
  if (e.substr(k, 2) == '13') {
    return `${g}PrintableString '${hextoutf8(
      ASN1HEX.getHexOfV_AtObj(e, k)
    )}'\n`;
  }
  if (e.substr(k, 2) == '14') {
    return `${g}TeletexString '${hextoutf8(ASN1HEX.getHexOfV_AtObj(e, k))}'\n`;
  }
  if (e.substr(k, 2) == '16') {
    return `${g}IA5String '${hextoutf8(ASN1HEX.getHexOfV_AtObj(e, k))}'\n`;
  }
  if (e.substr(k, 2) == '17') {
    return `${g}UTCTime ${hextoutf8(ASN1HEX.getHexOfV_AtObj(e, k))}\n`;
  }
  if (e.substr(k, 2) == '18') {
    return `${g}GeneralizedTime ${hextoutf8(ASN1HEX.getHexOfV_AtObj(e, k))}\n`;
  }
  if (e.substr(k, 2) == '30') {
    if (e.substr(k, 4) == '3000') {
      return `${g}SEQUENCE {}\n`;
    }
    var j = `${g}SEQUENCE\n`;
    var d = ASN1HEX.getPosArrayOfChildren_AtObj(e, k);
    let f = c;
    if (
      (d.length == 2 || d.length == 3) &&
      e.substr(d[0], 2) == '06' &&
      e.substr(d[d.length - 1], 2) == '04'
    ) {
      const t = ASN1HEX.getHexOfV_AtObj(e, d[0]);
      var a = KJUR.asn1.ASN1Util.oidHexToInt(t);
      var n = KJUR.asn1.x509.OID.oid2name(a);
      const p = JSON.parse(JSON.stringify(c));
      p.x509ExtName = n;
      f = p;
    }
    for (var q = 0; q < d.length; q++) {
      j += ASN1HEX.dump(e, f, d[q], `${g}  `);
    }
    return j;
  }
  if (e.substr(k, 2) == '31') {
    var j = `${g}SET\n`;
    var d = ASN1HEX.getPosArrayOfChildren_AtObj(e, k);
    for (var q = 0; q < d.length; q++) {
      j += ASN1HEX.dump(e, c, d[q], `${g}  `);
    }
    return j;
  }
  const u = parseInt(e.substr(k, 2), 16);
  if ((u & 128) != 0) {
    const m = u & 31;
    if ((u & 32) != 0) {
      var j = `${g}[${m}]\n`;
      var d = ASN1HEX.getPosArrayOfChildren_AtObj(e, k);
      for (var q = 0; q < d.length; q++) {
        j += ASN1HEX.dump(e, c, d[q], `${g}  `);
      }
      return j;
    }
    var h = ASN1HEX.getHexOfV_AtObj(e, k);
    if (h.substr(0, 8) == '68747470') {
      h = hextoutf8(h);
    }
    if (c.x509ExtName === 'subjectAltName' && m == 2) {
      h = hextoutf8(h);
    }
    var j = `${g}[${m}] ${h}\n`;
    return j;
  }
  return `${g}UNKNOWN(${e.substr(k, 2)}) ${ASN1HEX.getHexOfV_AtObj(e, k)}\n`;
};
ASN1HEX.isASN1HEX = function (d) {
  if (d.length % 2 == 1) {
    return false;
  }
  const c = ASN1HEX.getIntOfL_AtObj(d, 0);
  const b = d.substr(0, 2);
  const e = ASN1HEX.getHexOfL_AtObj(d, 0);
  const a = d.length - b.length - e.length;
  if (a == c * 2) {
    return true;
  }
  return false;
};
// convert a base64 string to hex
export function b64tohex(s) {
  let ret = '';
  let i;
  let k = 0; // b64 state, 0-3
  let slop;
  let v;
  for (i = 0; i < s.length; ++i) {
    if (s.charAt(i) == b64pad) break;
    v = b64map.indexOf(s.charAt(i));
    if (v < 0) continue;
    if (k == 0) {
      ret += int2char(v >> 2);
      slop = v & 3;
      k = 1;
    } else if (k == 1) {
      ret += int2char((slop << 2) | (v >> 4));
      slop = v & 0xf;
      k = 2;
    } else if (k == 2) {
      ret += int2char(slop);
      ret += int2char(v >> 2);
      slop = v & 3;
      k = 3;
    } else {
      ret += int2char((slop << 2) | (v >> 4));
      ret += int2char(v & 0xf);
      k = 0;
    }
  }
  if (k == 1) {
    ret += int2char(slop << 2);
  }
  return ret;
}
