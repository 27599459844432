// import publicCert from './cert/autentia.pem';
import { X509 } from './lib/x509.js';
import {
  MBResponse,
  AutentiaResponse,
  TransactionType,
  PaqueteType,
  ParamsSetType,
  TrxParams,
  ProcessProps,
} from './types';
const publicCert = require('./cert/autentia.pem');

const exitoAutentia = (response: AutentiaResponse) =>
  response.ParamsGet?.erc === 0;

const blockWindow = (block: boolean) => {
  const element = document.getElementById('autentia-block');
  if (block) {
    if (!element) {
      const blockElement = document.createElement('div');
      blockElement.id = 'autentia-block';
      blockElement.innerHTML = 'Ejecutando Transaccion Autentia...';
      let styles = 'color: white;';
      styles += 'position: absolute;';
      styles += 'background-color: rgba(0,0,0,.8);';
      styles += 'top: 0;';
      styles += 'bottom: 0;';
      styles += 'left: 0;';
      styles += 'right: 0;';
      styles += 'z-index: 9990;';
      styles += 'display: flex;';
      styles += 'justify-content: center;';
      styles += 'align-items: center;';
      blockElement.setAttribute('style', styles);
      document.body.append(blockElement);
    }
  } else if (element) {
    document.body.removeChild(element);
  }
};

const FirmaValidaMB = async (
  response: MBResponse,
  encodedResp: Response
): Promise<boolean> => {
  try {
    return encodedResp.text().then(async (responseText) => {
      const cert = await fetch(publicCert);
      const certContent = await cert.text();
      const Lx509 = new X509();
      Lx509.readCertPEM(certContent);
      let LtmpSignature = responseText.replace(
        /"signature":"[^"]+"/,
        '"signature":""'
      );
      let LfirmaValida = Lx509.subjectPublicKeyRSA.verifyString(
        LtmpSignature,
        response.signature
      );
      if (!LfirmaValida) {
        LtmpSignature = responseText.replace(/"token":"[^"]+"/, '"token":""');
        LfirmaValida = Lx509.subjectPublicKeyRSA.verifyString(
          LtmpSignature,
          response.signature
        );
      }
      return LfirmaValida;
    });
  } catch (error) {
    console.log({ error });
    blockWindow(false);
    return false;
  }
};

const procesarJSON = ({
  data,
  requerimiento,
  procesarResultado,
}: ProcessProps) => {
  const encodedData = JSON.stringify(data);
  try {
    const requestOptions = {
      method: 'POST',
      requestCert: true,
      headers: {
        'Content-Type': 'text/plain; charset=ISO8859_1',
      },
      body: encodedData,
    };
    const ajaxResult = fetch(
      `https://plugin.autentia.mb:7777/${requerimiento}/${data.token}`,
      requestOptions
    );
    ajaxResult
      .then((response) => {
        const responseClone = response.clone();
        response.json().then(async (responseJson) => {
          console.log(responseJson);
          if (data.token !== responseJson.token) {
            return procesarResultado({
              MBerc: -100,
              MBercText: `El token de la respuesta no calza con el enviado. ${responseJson.MBercText}`,
              MBversion: responseJson.MBversion,
            });
          }
          if (exitoAutentia(responseJson)) {
            if (await FirmaValidaMB(responseJson, responseClone)) {
              return procesarResultado(responseJson);
            }
            return procesarResultado({
              MBerc: -101,
              MBercText: `La firma de la respuesta es inválida. ${responseJson.MBercText}`,
              MBversion: responseJson.MBversion,
            });
          }
          return procesarResultado(responseJson);
        });
      })
      .catch((error) => {
        procesarResultado({
          ParamsGet: {
            erc: -101,
            ercText: error,
          },
        });
      });
  } catch (e: any) {
    procesarResultado({
      ParamsGet: {
        erc: -1,
        ercText: `ERROR: ${e.message}`,
      },
    });
  }
};

const Transaccion = ({
  trxName,
  input,
  output,
  hookAutentia,
  token,
  excResp,
}: TrxParams) => {
  if (!input || !output) {
    return excResp({
      ParamsGet: {
        erc: -1,
        ercText: 'ERROR: No se recibieron todos los parametros',
      },
    });
  }
  blockWindow(true);
  let prmSet: ParamsSetType[] = [];
  let objPaquete: PaqueteType = [];
  const ParamInit: string[] = [];
  let paramIndex = 1;
  // eslint-disable-next-line no-restricted-syntax
  for (const [index, [key, value]] of Object.entries(Object.entries(input))) {
    ParamInit.push(key);
    prmSet.push({
      idx: parseInt(index, 10) + 1,
      valor: value,
    });
    paramIndex += 1;
  }

  const paramsGet: PaqueteType = [];

  for (let j = 0; j < output.length; j += 1) {
    const param = output[j];
    if (!ParamInit.includes(param)) {
      ParamInit.push(param);
      paramsGet.push({
        comando: 'ParamsGet',
        param: j + paramIndex,
        paramName: param,
      });
    }
  }

  objPaquete.push({
    comando: 'ParamsInit',
    param: ParamInit.join(','),
  });
  if (prmSet.length) {
    objPaquete.push({
      comando: 'ParamsSet',
      param: prmSet,
    });
  }
  objPaquete.push({
    comando: 'Transaccion',
    param: trxName,
  });
  paramsGet.map((param) => objPaquete.push(param));
  let transaccion: TransactionType = {
    paquete: objPaquete,
    hookAutentia,
    token,
  };
  return procesarJSON({
    data: transaccion,
    requerimiento: 'json-handler',
    procesarResultado: (result) => {
      transaccion = {};
      objPaquete = [];
      prmSet = [];
      blockWindow(false);
      return excResp(result);
    },
  });
};

export const Autentia = {
  Transaccion,
};
