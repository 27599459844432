import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

import './App.css';

// components
import { HomeComponent } from './components/HomeComponent';

const theme = createTheme({
  palette: {
    primary: {
      main: '#015fff',
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/:id?',
    element: <HomeComponent />,
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </ThemeProvider>
  );
}

export default App;
