/*! x509-1.1.6.js (c) 2012-2015 Kenji Urushima | kjur.github.com/jsrsasign/license
 */
import { ASN1HEX, b64tohex } from './ans1';
import { BigInteger } from './jsbn';
import { RSAKey } from './rsa';

export class X509 {
  constructor() {
    this.subjectPublicKeyRSA = null;
    this.subjectPublicKeyRSA_hN = null;
    this.subjectPublicKeyRSA_hE = null;
    this.hex = null;
    this.getSerialNumberHex = function () {
      return ASN1HEX.getDecendantHexVByNthList(this.hex, 0, [0, 1]);
    };
    this.getIssuerHex = function () {
      return ASN1HEX.getDecendantHexTLVByNthList(this.hex, 0, [0, 3]);
    };
    this.getIssuerString = function () {
      return X509.hex2dn(
        ASN1HEX.getDecendantHexTLVByNthList(this.hex, 0, [0, 3])
      );
    };
    this.getSubjectHex = function () {
      return ASN1HEX.getDecendantHexTLVByNthList(this.hex, 0, [0, 5]);
    };
    this.getSubjectString = function () {
      return X509.hex2dn(
        ASN1HEX.getDecendantHexTLVByNthList(this.hex, 0, [0, 5])
      );
    };
    this.getNotBefore = function () {
      let a = ASN1HEX.getDecendantHexVByNthList(this.hex, 0, [0, 4, 0]);
      a = a.replace(/(..)/g, '%$1');
      a = decodeURIComponent(a);
      return a;
    };
    this.getNotAfter = function () {
      let a = ASN1HEX.getDecendantHexVByNthList(this.hex, 0, [0, 4, 1]);
      a = a.replace(/(..)/g, '%$1');
      a = decodeURIComponent(a);
      return a;
    };
    this.readCertPEM = function (c) {
      const e = X509.pemToHex(c);
      const b = X509.getPublicKeyHexArrayFromCertHex(e);
      const d = new RSAKey();
      d.setPublic(b[0], b[1]);
      this.subjectPublicKeyRSA = d;
      this.subjectPublicKeyRSA_hN = b[0];
      this.subjectPublicKeyRSA_hE = b[1];
      this.hex = e;
    };
    this.readCertPEMWithoutRSAInit = function (c) {
      const d = X509.pemToHex(c);
      const b = X509.getPublicKeyHexArrayFromCertHex(d);
      this.subjectPublicKeyRSA.setPublic(b[0], b[1]);
      this.subjectPublicKeyRSA_hN = b[0];
      this.subjectPublicKeyRSA_hE = b[1];
      this.hex = d;
    };
  }
  static pemToBase64(a) {
    let b = a;
    b = b.replace('-----BEGIN CERTIFICATE-----', '');
    b = b.replace('-----END CERTIFICATE-----', '');
    b = b.replace(/[ \n]+/g, '');
    return b;
  }
  static pemToHex(a) {
    const c = X509.pemToBase64(a);
    const b = b64tohex(c);
    return b;
  }
  static getSubjectPublicKeyPosFromCertHex(f) {
    const e = X509.getSubjectPublicKeyInfoPosFromCertHex(f);
    if (e == -1) {
      return -1;
    }
    const b = ASN1HEX.getPosArrayOfChildren_AtObj(f, e);
    if (b.length != 2) {
      return -1;
    }
    const d = b[1];
    if (f.substring(d, d + 2) != '03') {
      return -1;
    }
    const c = ASN1HEX.getStartPosOfV_AtObj(f, d);
    if (f.substring(c, c + 2) != '00') {
      return -1;
    }
    return c + 2;
  }
  static getSubjectPublicKeyInfoPosFromCertHex(d) {
    const c = ASN1HEX.getStartPosOfV_AtObj(d, 0);
    const b = ASN1HEX.getPosArrayOfChildren_AtObj(d, c);
    if (b.length < 1) {
      return -1;
    }
    if (d.substring(b[0], b[0] + 10) == 'a003020102') {
      if (b.length < 6) {
        return -1;
      }
      return b[6];
    }
    if (b.length < 5) {
      return -1;
    }
    return b[5];
  }
  static getPublicKeyHexArrayFromCertHex(f) {
    const e = X509.getSubjectPublicKeyPosFromCertHex(f);
    const b = ASN1HEX.getPosArrayOfChildren_AtObj(f, e);
    if (b.length != 2) {
      return [];
    }
    const d = ASN1HEX.getHexOfV_AtObj(f, b[0]);
    const c = ASN1HEX.getHexOfV_AtObj(f, b[1]);
    if (d != null && c != null) {
      return [d, c];
    }
    return [];
  }
  static getHexTbsCertificateFromCert(b) {
    const a = ASN1HEX.getStartPosOfV_AtObj(b, 0);
    return a;
  }
  static getPublicKeyHexArrayFromCertPEM(c) {
    const d = X509.pemToHex(c);
    const b = X509.getPublicKeyHexArrayFromCertHex(d);
    return b;
  }
  static hex2dn(e) {
    let f = '';
    const c = ASN1HEX.getPosArrayOfChildren_AtObj(e, 0);
    for (let d = 0; d < c.length; d++) {
      const b = ASN1HEX.getHexOfTLV_AtObj(e, c[d]);
      f = `${f}/${X509.hex2rdn(b)}`;
    }
    return f;
  }
  static hex2rdn(a) {
    const f = ASN1HEX.getDecendantHexTLVByNthList(a, 0, [0, 0]);
    let e = ASN1HEX.getDecendantHexVByNthList(a, 0, [0, 1]);
    let c = '';
    try {
      c = X509.DN_ATTRHEX[f];
    } catch (b) {
      c = f;
    }
    e = e.replace(/(..)/g, '%$1');
    const d = decodeURIComponent(e);
    return `${c}=${d}`;
  }
  static getPublicKeyFromCertPEM(f) {
    const c = X509.getPublicKeyInfoPropOfCertPEM(f);
    if (c.algoid == '2a864886f70d010101') {
      /* eslint-disable  */
      const i = KEYUTIL.parsePublicRawRSAKeyHex(c.keyhex);
      var j = new RSAKey();
      j.setPublic(i.n, i.e);
      return j;
    }
    if (c.algoid == '2a8648ce3d0201') {
      const e = KJUR.crypto.OID.oidhex2name[c.algparam];
      var j = new KJUR.crypto.ECDSA({ curve: e, info: c.keyhex });
      j.setPublicKeyHex(c.keyhex);
      return j;
    }
    if (c.algoid == '2a8648ce380401') {
      const b = ASN1HEX.getVbyList(c.algparam, 0, [0], '02');
      const a = ASN1HEX.getVbyList(c.algparam, 0, [1], '02');
      const d = ASN1HEX.getVbyList(c.algparam, 0, [2], '02');
      let h = ASN1HEX.getHexOfV_AtObj(c.keyhex, 0);
      h = h.substr(2);
      var j = new KJUR.crypto.DSA();
      j.setPublic(
        new BigInteger(b, 16),
        new BigInteger(a, 16),
        new BigInteger(d, 16),
        new BigInteger(h, 16)
      );
      return j;
    }
    throw 'unsupported key';
  }
  static getPublicKeyInfoPropOfCertPEM(e) {
    const c = {};
    c.algparam = null;
    const g = X509.pemToHex(e);
    const d = ASN1HEX.getPosArrayOfChildren_AtObj(g, 0);
    if (d.length != 3) {
      throw 'malformed X.509 certificate PEM (code:001)';
    }
    if (g.substr(d[0], 2) != '30') {
      throw 'malformed X.509 certificate PEM (code:002)';
    }
    const b = ASN1HEX.getPosArrayOfChildren_AtObj(g, d[0]);
    if (b.length < 7) {
      throw 'malformed X.509 certificate PEM (code:003)';
    }
    const h = ASN1HEX.getPosArrayOfChildren_AtObj(g, b[6]);
    if (h.length != 2) {
      throw 'malformed X.509 certificate PEM (code:004)';
    }
    const f = ASN1HEX.getPosArrayOfChildren_AtObj(g, h[0]);
    if (f.length != 2) {
      throw 'malformed X.509 certificate PEM (code:005)';
    }
    c.algoid = ASN1HEX.getHexOfV_AtObj(g, f[0]);
    if (g.substr(f[1], 2) == '06') {
      c.algparam = ASN1HEX.getHexOfV_AtObj(g, f[1]);
    } else if (g.substr(f[1], 2) == '30') {
      c.algparam = ASN1HEX.getHexOfTLV_AtObj(g, f[1]);
    }
    if (g.substr(h[1], 2) != '03') {
      throw 'malformed X.509 certificate PEM (code:006)';
    }
    const a = ASN1HEX.getHexOfV_AtObj(g, h[1]);
    c.keyhex = a.substr(2);
    return c;
  }
  static getPublicKeyInfoPosOfCertHEX(c) {
    const b = ASN1HEX.getPosArrayOfChildren_AtObj(c, 0);
    if (b.length != 3) {
      throw 'malformed X.509 certificate PEM (code:001)';
    }
    if (c.substr(b[0], 2) != '30') {
      throw 'malformed X.509 certificate PEM (code:002)';
    }
    const a = ASN1HEX.getPosArrayOfChildren_AtObj(c, b[0]);
    if (a.length < 7) {
      throw 'malformed X.509 certificate PEM (code:003)';
    }
    return a[6];
  }
  static getV3ExtInfoListOfCertHex(g) {
    const b = ASN1HEX.getPosArrayOfChildren_AtObj(g, 0);
    if (b.length != 3) {
      throw 'malformed X.509 certificate PEM (code:001)';
    }
    if (g.substr(b[0], 2) != '30') {
      throw 'malformed X.509 certificate PEM (code:002)';
    }
    const a = ASN1HEX.getPosArrayOfChildren_AtObj(g, b[0]);
    if (a.length < 8) {
      throw 'malformed X.509 certificate PEM (code:003)';
    }
    if (g.substr(a[7], 2) != 'a3') {
      throw 'malformed X.509 certificate PEM (code:004)';
    }
    const h = ASN1HEX.getPosArrayOfChildren_AtObj(g, a[7]);
    if (h.length != 1) {
      throw 'malformed X.509 certificate PEM (code:005)';
    }
    if (g.substr(h[0], 2) != '30') {
      throw 'malformed X.509 certificate PEM (code:006)';
    }
    const f = ASN1HEX.getPosArrayOfChildren_AtObj(g, h[0]);
    const e = f.length;
    const d = new Array(e);
    for (let c = 0; c < e; c++) {
      d[c] = X509.getV3ExtItemInfo_AtObj(g, f[c]);
    }
    return d;
  }
  static getV3ExtItemInfo_AtObj(f, g) {
    const e = {};
    e.posTLV = g;
    const b = ASN1HEX.getPosArrayOfChildren_AtObj(f, g);
    if (b.length != 2 && b.length != 3) {
      throw 'malformed X.509v3 Ext (code:001)';
    }
    if (f.substr(b[0], 2) != '06') {
      throw 'malformed X.509v3 Ext (code:002)';
    }
    const d = ASN1HEX.getHexOfV_AtObj(f, b[0]);
    e.oid = ASN1HEX.hextooidstr(d);
    e.critical = false;
    if (b.length == 3) {
      e.critical = true;
    }
    const c = b[b.length - 1];
    if (f.substr(c, 2) != '04') {
      throw 'malformed X.509v3 Ext (code:003)';
    }
    e.posV = ASN1HEX.getStartPosOfV_AtObj(f, c);
    return e;
  }
  static getHexOfTLV_V3ExtValue(b, a) {
    const c = X509.getPosOfTLV_V3ExtValue(b, a);
    if (c == -1) {
      return '';
    }
    return ASN1HEX.getHexOfTLV_AtObj(b, c);
  }
  static getHexOfV_V3ExtValue(b, a) {
    const c = X509.getPosOfTLV_V3ExtValue(b, a);
    if (c == -1) {
      return '';
    }
    return ASN1HEX.getHexOfV_AtObj(b, c);
  }
  static getPosOfTLV_V3ExtValue(f, b) {
    let d = b;
    if (!b.match(/^[0-9.]+$/)) {
      d = KJUR.asn1.x509.OID.name2oid(b);
    }
    if (d == '') {
      return -1;
    }
    const c = X509.getV3ExtInfoListOfCertHex(f);
    for (let a = 0; a < c.length; a++) {
      const e = c[a];
      if (e.oid == d) {
        return e.posV;
      }
    }
    return -1;
  }
  static getExtKeyUsageBin(d) {
    const b = X509.getHexOfV_V3ExtValue(d, 'keyUsage');
    if (b == '') {
      return '';
    }
    if (b.length % 2 != 0 || b.length <= 2) {
      throw 'malformed key usage value';
    }
    const a = parseInt(b.substr(0, 2));
    const c = parseInt(b.substr(2), 16).toString(2);
    return c.substr(0, c.length - a);
  }
  static getExtKeyUsageString(e) {
    const d = X509.getExtKeyUsageBin(e);
    const b = new Array();
    for (let c = 0; c < d.length; c++) {
      if (d.substr(c, 1) == '1') {
        b.push(X509.KEYUSAGE_NAME[c]);
      }
    }
    return b.join(',');
  }
  static getExtAIAInfo(g) {
    const j = {};
    j.ocsp = [];
    j.caissuer = [];
    const h = X509.getPosOfTLV_V3ExtValue(g, 'authorityInfoAccess');
    if (h == -1) {
      return null;
    }
    if (g.substr(h, 2) != '30') {
      throw 'malformed AIA Extn Value';
    }
    const d = ASN1HEX.getPosArrayOfChildren_AtObj(g, h);
    for (let c = 0; c < d.length; c++) {
      const a = d[c];
      const b = ASN1HEX.getPosArrayOfChildren_AtObj(g, a);
      if (b.length != 2) {
        throw 'malformed AccessDescription of AIA Extn';
      }
      const e = b[0];
      const f = b[1];
      if (ASN1HEX.getHexOfV_AtObj(g, e) == '2b06010505073001') {
        if (g.substr(f, 2) == '86') {
          j.ocsp.push(hextoutf8(ASN1HEX.getHexOfV_AtObj(g, f)));
        }
      }
      if (ASN1HEX.getHexOfV_AtObj(g, e) == '2b06010505073002') {
        if (g.substr(f, 2) == '86') {
          j.caissuer.push(hextoutf8(ASN1HEX.getHexOfV_AtObj(g, f)));
        }
      }
    }
    return j;
  }
}
X509.DN_ATTRHEX = {
  '0603550406': 'C',
  '060355040a': 'O',
  '060355040b': 'OU',
  '0603550403': 'CN',
  '0603550405': 'SN',
  '0603550408': 'ST',
  '0603550407': 'L',
};
X509.KEYUSAGE_NAME = [
  'digitalSignature',
  'nonRepudiation',
  'keyEncipherment',
  'dataEncipherment',
  'keyAgreement',
  'keyCertSign',
  'cRLSign',
  'encipherOnly',
  'decipherOnly',
];
