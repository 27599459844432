/*! (c) Tom Wu | http://www-cs-students.stanford.edu/~tjw/jsbn/
 */
// Depends on jsbn.js and rng.js

// Version 1.1: support utf-8 encoding in pkcs1pad2

// convert a (hex) string to a bignum object

import { SHA1 } from 'crypto-js';

import { BigInteger } from './jsbn';

const _RE_HEXDECONLY = new RegExp('');
_RE_HEXDECONLY.compile('[^0-9a-f]', 'gi');

function parseBigInt(str, r) {
  return new BigInteger(str, r);
}

function linebrk(s, n) {
  let ret = '';
  let i = 0;
  while (i + n < s.length) {
    ret += `${s.substring(i, i + n)}\n`;
    i += n;
  }
  return ret + s.substring(i, s.length);
}

function byte2Hex(b) {
  if (b < 0x10) {
    return `0${b.toString(16)}`;
  }
  return b.toString(16);
}

// PKCS#1 (type 2, random) pad input string s to n bytes, and return a bigint
function pkcs1pad2(s, n) {
  if (n < s.length + 11) {
    // TODO: fix for utf-8
    alert('Message too long for RSA');
    return null;
  }
  const ba = new Array();
  let i = s.length - 1;
  while (i >= 0 && n > 0) {
    const c = s.charCodeAt(i--);
    if (c < 128) {
      // encode using utf-8
      ba[--n] = c;
    } else if (c > 127 && c < 2048) {
      ba[--n] = (c & 63) | 128;
      ba[--n] = (c >> 6) | 192;
    } else {
      ba[--n] = (c & 63) | 128;
      ba[--n] = ((c >> 6) & 63) | 128;
      ba[--n] = (c >> 12) | 224;
    }
  }
  ba[--n] = 0;
  /* eslint-disable */
  const rng = new SecureRandom();
  const x = new Array();
  while (n > 2) {
    // random non-zero pad
    x[0] = 0;
    while (x[0] == 0) rng.nextBytes(x);
    ba[--n] = x[0];
  }
  ba[--n] = 2;
  ba[--n] = 0;
  return new BigInteger(ba);
}

// PKCS#1 (OAEP) mask generation function
function oaep_mgf1_arr(seed, len, hash) {
  let mask = '';
  let i = 0;

  while (mask.length < len) {
    mask += hash(
      String.fromCharCode.apply(
        String,
        seed.concat([
          (i & 0xff000000) >> 24,
          (i & 0x00ff0000) >> 16,
          (i & 0x0000ff00) >> 8,
          i & 0x000000ff,
        ])
      )
    );
    i += 1;
  }

  return mask;
}

const SHA1_SIZE = 20;

// PKCS#1 (OAEP) pad input string s to n bytes, and return a bigint
function oaep_pad(s, n, hash) {
  if (s.length + 2 * SHA1_SIZE + 2 > n) {
    throw 'Message too long for RSA';
  }

  let PS = '';
  let i;

  for (i = 0; i < n - s.length - 2 * SHA1_SIZE - 2; i += 1) {
    PS += '\x00';
  }

  const DB = `${rstr_sha1('') + PS}\x01${s}`;
  const seed = new Array(SHA1_SIZE);
  new SecureRandom().nextBytes(seed);

  const dbMask = oaep_mgf1_arr(seed, DB.length, hash || rstr_sha1);
  const maskedDB = [];

  for (i = 0; i < DB.length; i += 1) {
    maskedDB[i] = DB.charCodeAt(i) ^ dbMask.charCodeAt(i);
  }

  const seedMask = oaep_mgf1_arr(maskedDB, seed.length, rstr_sha1);
  const maskedSeed = [0];

  for (i = 0; i < seed.length; i += 1) {
    maskedSeed[i + 1] = seed[i] ^ seedMask.charCodeAt(i);
  }

  return new BigInteger(maskedSeed.concat(maskedDB));
}

// "empty" RSA key constructor
export function RSAKey() {
  this.n = null;
  this.e = 0;
  this.d = null;
  this.p = null;
  this.q = null;
  this.dmp1 = null;
  this.dmq1 = null;
  this.coeff = null;
}

// Set the public key fields N and e from hex strings
function RSASetPublic(N, E) {
  this.isPublic = true;
  if (typeof N !== 'string') {
    this.n = N;
    this.e = E;
  } else if (N != null && E != null && N.length > 0 && E.length > 0) {
    this.n = parseBigInt(N, 16);
    this.e = parseInt(E, 16);
  } else {
    alert('Invalid RSA public key');
  }
}

// Perform raw public operation on "x": return x^e (mod n)
function RSADoPublic(x) {
  return x.modPowInt(this.e, this.n);
}

// Return the PKCS#1 RSA encryption of "text" as an even-length hex string
function RSAEncrypt(text) {
  const m = pkcs1pad2(text, (this.n.bitLength() + 7) >> 3);
  if (m == null) return null;
  const c = this.doPublic(m);
  if (c == null) return null;
  const h = c.toString(16);
  if ((h.length & 1) == 0) return h;
  return `0${h}`;
}

// Return the PKCS#1 OAEP RSA encryption of "text" as an even-length hex string
function RSAEncryptOAEP(text, hash) {
  const m = oaep_pad(text, (this.n.bitLength() + 7) >> 3, hash);
  if (m == null) return null;
  const c = this.doPublic(m);
  if (c == null) return null;
  const h = c.toString(16);
  if ((h.length & 1) == 0) return h;
  return `0${h}`;
}

// Return the PKCS#1 RSA encryption of "text" as a Base64-encoded string
// function RSAEncryptB64(text) {
//  var h = this.encrypt(text);
//  if(h) return hex2b64(h); else return null;
// }

const DIGESTINFOHEAD = {
  sha1: '3021300906052b0e03021a05000414',
  sha224: '302d300d06096086480165030402040500041c',
  sha256: '3031300d060960864801650304020105000420',
  sha384: '3041300d060960864801650304020205000430',
  sha512: '3051300d060960864801650304020305000440',
  md2: '3020300c06082a864886f70d020205000410',
  md5: '3020300c06082a864886f70d020505000410',
  ripemd160: '3021300906052b2403020105000414',
};

function hashString(s, alg) {
  if (alg !== undefined) {
    if (alg !== undefined) {
      switch (alg) {
        case 'sha1':
          return SHA1(s);
        default:
          return '';
      }
    }
  }
}

function _rsasign_getAlgNameAndHashFromHexDisgestInfo(f) {
  for (const e in DIGESTINFOHEAD) {
    const d = DIGESTINFOHEAD[e];
    const b = d.length;
    if (f.substring(0, b) == d) {
      const c = [e, f.substring(b)];
      return c;
    }
  }
  return [];
}
function _rsasign_verifyString(f, j) {
  j = j.replace(_RE_HEXDECONLY, '');
  j = j.replace(/[ \n]+/g, '');
  const b = parseBigInt(j, 16);
  if (b.bitLength() > this.n.bitLength()) {
    return 0;
  }
  const i = this.doPublic(b);
  const e = i.toString(16).replace(/^1f+00/, '');
  const g = _rsasign_getAlgNameAndHashFromHexDisgestInfo(e);
  if (g.length == 0) {
    return false;
  }
  const d = g[0];
  const h = g[1];
  const a = function (k) {
    return hashString(k, d);
  };
  const c = a(f);
  return h == c;
}

// protected
RSAKey.prototype.doPublic = RSADoPublic;

// public
RSAKey.prototype.setPublic = RSASetPublic;
RSAKey.prototype.encrypt = RSAEncrypt;
RSAKey.prototype.encryptOAEP = RSAEncryptOAEP;
// RSAKey.prototype.encrypt_b64 = RSAEncryptB64;
RSAKey.prototype.verifyString = _rsasign_verifyString;

RSAKey.prototype.type = 'RSA';
